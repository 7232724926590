import { OfferEntity } from '@thirsty-camel/hump-club/src/modules/offer/offer.entity'
import { Thunk, thunk, Action, action } from 'easy-peasy'
import { AuthModel } from './auth'
import { BackendError } from '@thirstycamel/ui'
import { humpclub } from '../../utils/backend'
import { StoreModel } from '.'

export interface OffersModel {
  data: OfferEntity[]
  fetchOffers: Thunk<OffersModel, void, any, StoreModel>
  redeemOfferInStore: Thunk<OffersModel, { id: number; store: string }, any, StoreModel>
  applyCodeword: Thunk<OffersModel, string, any, StoreModel>
  error?: BackendError
  isFetching: boolean
  setStatus: Action<AuthModel, { isFetching: boolean; error?: BackendError }>
  setOffers: Action<OffersModel, OfferEntity[]>
}

const offers: OffersModel = {
  data: [],
  isFetching: false,
  error: null,
  setStatus: action((state, { isFetching, error }) => {
    state.isFetching = isFetching
    state.error = error
  }),
  setOffers: action((state, offers) => {
    state.data = offers
  }),
  fetchOffers: thunk(async (actions, _, helpers) => {
    const state = helpers.getStoreState()
    const token = state.auth.token
    actions.setStatus({ isFetching: true })

    const region = state?.store?.selectedStore?.region || state?.location?.region || 'VIC'

    try {
      let offers = await humpclub<OfferEntity[]>('users/me/offers', {
        method: 'get',
        headers: { Authorization: `Bearer ${token}` },
        params: {
          storeID:
            state?.store?.selectedStore?.humpClubStoreCode ?? state?.store?.selectedStore?.slug,
          region,
        },
      })

      if (!offers?.length) {
        await new Promise<void>(res => setTimeout(() => res(), 3000))
        offers = await humpclub<OfferEntity[]>('users/me/offers', {
          method: 'get',
          headers: { Authorization: `Bearer ${token}` },
          params: {
            storeID:
              state?.store?.selectedStore?.humpClubStoreCode ?? state?.store?.selectedStore?.slug,
            region,
          },
        })
      }

      actions.setOffers(offers)
      actions.setStatus({ isFetching: false, error: null })
    } catch (e) {
      actions.setStatus({ isFetching: false, error: e })
    }
  }),
  redeemOfferInStore: thunk(async (actions, value, helpers) => {
    const state = helpers.getStoreState()
    const token = state.auth.token
    actions.setStatus({ isFetching: true })

    try {
      const result = await humpclub<OfferEntity[]>(`/offers/${value.id}/redeem-instore`, {
        method: 'post',
        headers: { Authorization: `Bearer ${token}` },
        data: {
          store: value.store,
        },
      })

      actions.setStatus({ isFetching: false, error: null })
      return result
    } catch (e) {
      actions.setStatus({ isFetching: false, error: e })
      throw e
    }
  }),
  applyCodeword: thunk(async (actions, codeword, helpers) => {
    const state = helpers.getStoreState()
    const token = state.auth.token
    actions.setStatus({ isFetching: true })

    try {
      const result = await humpclub<OfferEntity[]>('/users/me/codeword', {
        method: 'post',
        headers: { Authorization: `Bearer ${token}` },
        data: {
          codeword,
        },
      })

      actions.setStatus({ isFetching: false, error: null })
      return result
    } catch (e) {
      actions.setStatus({ isFetching: false, error: e })
      throw e
    }
  }),
}

export default offers
