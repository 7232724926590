import React, { useCallback, useEffect, useMemo } from 'react'
import {
  Box,
  Carousel,
  ProgressiveImage,
  Skeleton,
  Container,
  Image,
  Flex,
  Heading,
  Text,
  CarouselButton,
  Link,
  Stack,
} from '@thirstycamel/ui'
import { gql } from 'graphql.macro'
import useMedia from 'use-media'
import dayjs from 'dayjs'

import { useStore } from '../../store/hooks'
import { Banner, BannersDocument } from '../../../generated'
import { config } from '../../utils/config'
import imageProxy from '../../utils/imageProxy'
import { useQuery } from 'react-query'
import { fetchGraphQL } from '../../utils/graphql-fetch'
import { eventGA4, eventUA } from '../../utils/gtag'
import { useGoogleAnalytics } from '../GoogleAnalyticsProvider'
import { useApp } from '../../hooks/useApp'

const AUTOPLAY = 1000 * 7 // 7 seconds
const BANNERS_FETCH_LIMIT = 20 // Different to the max number of banners shown on the site
const BANNERS_SHOWING_LIMIT = 10

/* This algorithm is not really that random, nor does it work well on larger arrays - it
 * should be fine for this use-case, however. */
const handleSortRandom = () => {
  return 0.5 - Math.random()
}

gql`
  query Banners($first: Int, $region: String) {
    banners(first: $first, where: { region: $region }) {
      edges {
        node {
          title
          acf {
            type
            link
            endsAt
            region
            storeCode
            imageDesktop {
              sourceUrl
            }
            imageMobile {
              sourceUrl
            }
          }
        }
      }
    }
  }
`

export const HomeBanner = () => {
  const isMobile = useMedia({ maxWidth: '30em' })
  const { isMobileApp } = useApp()
  const { trackEvent } = useGoogleAnalytics()

  const location = useStore(s => s.location)
  const selectedStore = useStore(s => s.store.selectedStore)
  const isDelivery = useStore(store => store.cart?.cart?.type === 'TAH')

  let region = selectedStore?.region || location.region || 'VIC'

  /* If region is NSWBR then change it to NSW_BR as this is how the CMS is setup */
  if (region === 'NSWBR') {
    region = 'NSW_BR'
  }

  const bannerType = isDelivery ? 'DELIVERY' : 'CLICK_AND_COLLECT'

  const query = useQuery<any, any>(
    ['api/cms', BannersDocument, { first: BANNERS_FETCH_LIMIT, region, type: bannerType }],
    fetchGraphQL,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 30000,
    },
  )

  /* Determine whether to use the desktop or mobile image for the banners. */
  const imageKey = isMobile ? 'imageMobile' : 'imageDesktop'

  const handleFilter = useCallback(
    (banner: { node: Banner }) => {
      if (!banner?.node) return false

      /* Check if the banner is the correct type */
      if (banner.node.acf?.type?.length) {
        const isCorrectType = banner.node.acf.type.includes(bannerType)

        if (!isCorrectType) return false
      }

      /* Check if the banner has expired */
      if (banner.node.acf?.endsAt) {
        /* Set the `endsAt` date (which is just MM/DD/YYYY) to 11:59pm in AEST (+10). */
        const endsAt = dayjs(`${banner.node.acf.endsAt} 23:59:59 +1000`)
        const isExpired = dayjs(endsAt).isBefore(Date.now())

        if (isExpired) return false
      }

      /* Check that the banner is valid for the region */
      if (banner.node.acf?.region?.length) {
        const isCorrectRegion = banner.node.acf.region.includes(region)

        if (!isCorrectRegion) return false
      }

      /* Check that the banner has a store code */
      if (banner.node.acf?.storeCode) {
        const storeCodes = banner?.node?.acf?.storeCode?.split(',')
        const isCorrectStore = selectedStore?.humpClubStoreCode
          ? storeCodes?.includes(selectedStore?.humpClubStoreCode)
          : storeCodes?.includes(selectedStore?.slug)

        if (!isCorrectStore) return false
      }

      return true
    },
    [bannerType, region, selectedStore],
  )

  const banners: { node: Banner }[] = useMemo(
    () =>
      query?.data?.banners?.edges
        ?.sort?.(handleSortRandom)
        .filter(handleFilter)
        .slice(0, BANNERS_SHOWING_LIMIT),
    [query?.data?.banners?.edges, handleFilter],
  )

  useEffect(() => {
    if (!banners?.length) return

    banners.forEach(({ node: banner }, index) => {
      trackEvent('ec', 'addPromo', {
        name: banner.title,
        id: banner.id,
        creative: 'banner',
        position: `banner_slot${index}`,
        non_interaction: true,
      })
    })

    /* Send to Google Analytics */
    eventUA('view_promotion', {
      event_label: 'Shown homepage banner',
      non_interaction: true,
      event_category: 'ecommerce',
      promotions: banners.map(({ node: banner }) => ({
        name: banner.title,
        id: banner.id,
        creative_slot: 'banner',
      })),
    })

    // Send to GA4
    banners.forEach(({ node: banner }) =>
      eventGA4('view_promotion', {
        creative_slot: 'banner',
        promotion_id: banner.id,
        promotion_name: banner.title,
      }),
    )
  }, [banners])

  /* If there are no banners, and we aren't fetching, show nothing. */
  if (!query.isFetching && !banners?.length) return null

  const handleClickBanner = (banner: Banner, index: number) => {
    // trackEvent('ec', 'promotionClick', {
    //   name: banner.title,
    //   id: banner.id,
    //   creative: 'banner',
    //   position: `slot${index}`,
    // })

    trackEvent('ec', 'setAction', 'promo_click')
    trackEvent('send', 'event', 'Internal Promotions', 'click', banner.title)

    /* Send to Google Analytics */
    eventUA('select_promotion', {
      event_category: 'ecommerce',
      event_label: 'Clicked on homepage banner',
      promotions: [{ name: banner.title, id: banner.id, creative_slot: 'banner' }],
    })

    eventGA4('select_promotion', {
      creative_slot: 'banner',
      promotion_name: banner.title,
      promotion_id: banner.id,
    })
  }

  return (
    <Box bg="gray.50" borderBottom="1px" borderColor="gray.200">
      <Container px={[0, , 12]} size="lg" display="flex" pt={[, , 10]} pb={[0, , 12]}>
        <Box
          h="100%"
          w="100%"
          overflow={['hidden', , 'initial']}
          paddingBottom={['calc(200 / 300 * 100%)', 'calc(400 / 1400 * 100%)']}
          position="relative"
          border="1px solid"
          borderColor="gray.200"
        >
          <Box position="absolute" h="100%" w="100%">
            {query.isFetching && !banners?.length ? (
              <Skeleton h="100%" w="100%" />
            ) : banners?.length === 1 ? (
              banners?.map(banner => {
                const image = (
                  <Image
                    src={imageProxy(banner.node.acf[imageKey]?.sourceUrl, { wp: true })}
                    objectFit="cover"
                    w="100%"
                    h="100%"
                  />
                )

                if (!banners[0].node?.acf?.link) return image

                const isLinkExternal = isMobileApp
                  ? banner.node.acf?.link?.startsWith?.('http') &&
                    !banner.node.acf?.link?.startsWith?.('https://thirstycamel.com.au') &&
                    !banner.node.acf?.link?.startsWith?.('https://www.thirstycamel.com.au')
                  : banner.node.acf?.isLinkExternal || banner.node.acf?.link?.startsWith?.('http')

                const url = isLinkExternal
                  ? banner.node.acf.link
                  : banner.node.acf.link
                      ?.replace('https://thirstycamel.com.au', '')
                      ?.replace('https://www.thirstycamel.com.au', '')

                return (
                  <Link
                    href={url}
                    isExternal={isLinkExternal}
                    h="100%"
                    w="100%"
                    display="block"
                    // onClick={() => handleClickBanner(banners[0])}
                  >
                    {image}
                  </Link>
                )
              })
            ) : (
              <Carousel
                showDots
                arrows
                arrowLeft={<CarouselButton side="left" />}
                arrowRight={<CarouselButton side="right" />}
                infinite
                autoPlay={AUTOPLAY}
              >
                {banners.map(({ node: banner }, index) => {
                  const isLinkExternal = true
                    ? banner.acf?.link?.startsWith?.('http') &&
                      !banner.acf?.link?.startsWith?.('https://thirstycamel.com.au') &&
                      !banner.acf?.link?.startsWith?.('https://www.thirstycamel.com.au')
                    : banner.acf?.isLinkExternal || banner.acf?.link?.startsWith?.('http')

                  const image = (
                    <Image
                      key={banner.title}
                      src={imageProxy(banner.acf[imageKey]?.sourceUrl, { wp: true })}
                      objectFit="cover"
                      w="100%"
                      h="100%"
                    />
                  )

                  if (!banner.acf?.link) return image

                  const url = isLinkExternal
                    ? banner.acf.link
                    : banner.acf.link
                        ?.replace('https://thirstycamel.com.au', '')
                        ?.replace('https://www.thirstycamel.com.au', '')

                  return (
                    <Link
                      href={url}
                      isExternal={isLinkExternal}
                      h="100%"
                      w="100%"
                      display="block"
                      onClick={() => handleClickBanner(banner, index)}
                    >
                      {image}
                    </Link>
                  )
                })}
              </Carousel>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default HomeBanner
