import React, { memo, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import {
  Box,
  useTheme,
  BoxProps,
  Container,
  ContainerProps,
  Text,
  Flex,
  Heading,
  Stack,
  LinkButton,
  Graphic,
  Button,
  IconButton,
  useToast,
} from '@thirstycamel/ui'
import { useRouter } from 'next/router'

import { LayoutMain, LayoutMainProps } from '../LayoutMain'
import TrendingKeywords from '../../components/TrendingKeywords'
import { useActions, useStore } from '../../store/hooks'
import { useUser } from '../../hooks/useUser'
import DriveThruPinModal from '../../components/DriveThruPinModal'

export const getSidebarItems = user => [
  {
    href: '/account/offers',
    text: `My offers${user?.activeOffersCount ? ` (${user?.activeOffersCount})` : ''}`,
  },
  {
    href: '/account/offers/redeemed',
    text: `Redeemed Offers`,
  },
  { href: '/account', text: 'Account information' },
  { href: '/account/addresses', text: 'Address book' },
  { href: '/account/orders', text: 'My orders' },
  { href: '/account/reviews', text: 'My product reviews' },
  { href: '/account/products', text: 'Favourite products' },
  { href: '/account/email-preferences', text: 'Email preferences' },
]

export interface LayoutAccountProps extends LayoutMainProps {}

export function LayoutAccount({ children, ...restProps }: LayoutAccountProps) {
  const router = useRouter()
  const theme = useTheme()

  const [isSidebarVisible, setSidebarVisible] = useState(false)

  const { logout, getUser } = useActions(actions => actions.auth)
  const { showModal } = useActions(actions => actions.modal)
  const { isFetching } = useStore(store => store.auth)
  const { user, isLoggedIn, isHumpClubAvailable } = useUser()

  const toast = useToast()

  useEffect(() => {
    getUser()
  }, [])

  useEffect(() => {
    if (!isLoggedIn) {
      router.push('/login')
    }
  }, [isLoggedIn])

  const sidebarItems = getSidebarItems(user)

  return (
    <LayoutMain {...restProps} noContainer>
      <Flex>
        <Flex
          display={['flex', , 'none']}
          flexShrink={0}
          bg="gray.50"
          justify="center"
          p={2}
          borderBottom="2px"
          borderColor="gray.200"
        >
          <IconButton
            icon="menu"
            isRound
            fontSize="2xl"
            variantColor="pink"
            onClick={() => setSidebarVisible(state => !state)}
          />
        </Flex>

        <Flex align="center" bg="pink.500" py={[6, 6]} color="white" w="100%">
          <Container
            display="flex"
            justifyContent="center"
            alignItems="center"
            size="lg"
            px={[5, 10, 6, 16]}
          >
            <Graphic
              name="humpClubLogo"
              size={['128px', '160px', '192px']}
              transform="rotate(-10deg)"
              mr={6}
              flexShrink={[1, 0]}
              my={-8}
              pos="relative"
              zIndex={2}
              display={['none', , 'block']}
            />

            <Flex
              direction={['column', , 'row']}
              alignItems="flex-start"
              flexShrink={1}
              flexGrow={1}
            >
              <Flex justify="space-between" align="center" flexGrow={1} pr={6} mb={[3, , 0]}>
                {isHumpClubAvailable ? (
                  <Box>
                    <Text textTransform="uppercase" fontSize="sm" fontFamily="heading">
                      {user?.firstName} - you're a
                    </Text>

                    <Heading textTransform="uppercase" fontSize={['2xl', '3xl']}>
                      {user?.displayLevel}
                    </Heading>

                    <Text display="block">
                      {user?.level === 'VIRGIN_HUMPER'
                        ? 'Make your first purchase at selected stores or online to upgrade to a Rookie Member.'
                        : user?.nextSpendDetails?.spendAmount
                        ? `Spend $${
                            user?.nextSpendDetails?.spendAmount?.toFixed(2) || ''
                          }, at selected stores by ${dayjs(user?.nextSpendDetails?.spendBy).format(
                            'DD/MM/YY' || '',
                          )} to get your next reward.`
                        : `Congratulations, you have reached the top! Stay tuned for more campaigns and rewards.`}{' '}
                    </Text>
                  </Box>
                ) : (
                  <Heading textTransform="uppercase" fontSize={['2xl', '3xl']}>
                    Hump Club
                  </Heading>
                )}
              </Flex>

              <Stack>
                <LinkButton
                  href="/account/licence"
                  variant="outline"
                  variantColor="pink"
                  flexShrink={0}
                >
                  Your member card
                </LinkButton>
                {(user?.email?.toLowerCase().includes('@pipelabs.com.au') ||
                  user?.email?.toLowerCase().includes('@thirstycamel.com.au')) && (
                  <DriveThruPinModal />
                )}
              </Stack>
            </Flex>
          </Container>
        </Flex>
      </Flex>

      <Container display="flex" flexGrow={1} size="lg" px={0} pos="relative">
        <Flex
          direction="column"
          align="flex-start"
          flexGrow={1}
          w={['100vw', , '100%']}
          maxWidth={[, , '13rem', '15.7rem']}
          px={[10, , 8]}
          py={12}
          bg="gray.50"
          borderRight="1px"
          borderColor="gray.200"
          position={['fixed', , 'relative']}
          right={['100%', , 'auto']}
          top={0}
          bottom={0}
          transform={isSidebarVisible ? 'translateX(100%)' : null}
          zIndex={[theme.zIndices.overlay, , 0]}
          transition="200ms ease transform"
          overflowY={['auto', 'initial']}
        >
          <IconButton
            icon={isSidebarVisible ? 'close' : 'menu'}
            pos="absolute"
            right={3}
            top={3}
            zIndex={2}
            isRound
            fontSize={isSidebarVisible ? 'lg' : '2xl'}
            variantColor="pink"
            display={['flex', , 'none']}
            onClick={() => setSidebarVisible(state => !state)}
          />

          <Box position="absolute" right={0} top={0} bottom={0} w="100vw" bg="gray.50" />

          <Stack
            w="100%"
            mb={8}
            opacity={[isSidebarVisible ? 1 : 0, , 1]}
            transition="100ms ease opacity"
          >
            {sidebarItems.map(item => (
              <LinkButton
                key={item.href}
                variant="ghost"
                href={item.href}
                variantColor={router.route === item.href ? 'black' : 'pink'}
                textAlign="left"
                justifyContent="flex-start"
                w="100%"
                px={1}
                onClick={() => setSidebarVisible(false)}
              >
                {item.text}
              </LinkButton>
            ))}
          </Stack>

          <Button
            isLoading={isFetching}
            onClick={() => logout()}
            variant="outline"
            variantColor="pink"
          >
            Log out
          </Button>
        </Flex>

        <Box bg="white" p={[6, 8]} pb={12} width="100%">
          {children}
        </Box>
      </Container>

      <TrendingKeywords />
    </LayoutMain>
  )
}

export default memo(LayoutAccount)
